/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, Menu, ColumnWrapper, Title, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose, Button, ColumnWrap, Text, Image, Subtitle, PriceWrap, ContactForm, Fullmap, FullmapWrap, FullmapCover } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <Column className="--menu pb--08 pt--08" menu={true} name={"menu"} style={{"backgroundColor":"rgba(247, 245, 244, 1)"}}>
          
          <Menu className="pl--20 pr--20">
            
            <ColumnWrapper className="menu-logo-box">
              
              <Title className="title-box ff--2 fs--48 w--300" content={"Chalupa Jetřiška"}>
              </Title>

            </ColumnWrapper>

            <MenuWrap >
              
              <MenuButton >
              </MenuButton>

              <MenuWrapBox >
                
                <MenuButtonClose >
                </MenuButtonClose>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2 ff--2 fs--26 ls--01 pl--06 pr--06" href={"#vybaveni-nadpis"} target={""} content={"Úvod"}>
                </Button>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2 ff--2 fs--26 ls--01 pl--06 pr--06" href={"#cenik-nadpis"} target={""} content={"Ceník"}>
                </Button>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2 ff--2 fs--26 ls--01 pl--06 pr--06" href={"#fotogalerie1"} target={""} content={"Fotogalerie"}>
                </Button>

                <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2 ff--2 fs--26 ls--01 pl--06 pr--06" href={"#cwoqivjncan"} target={""} content={"Rezervace"}>
                </Button>

              </MenuWrapBox>

            </MenuWrap>

          </Menu>

        </Column>


        <Column className="css-hbshwa --full pb--30 pt--30" name={"hlavicka"} fullscreen={true} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/10767/c15d5498411e4d939a675f95199c7b96_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/10767/c15d5498411e4d939a675f95199c7b96_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/10767/c15d5498411e4d939a675f95199c7b96_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/10767/c15d5498411e4d939a675f95199c7b96_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/10767/c15d5498411e4d939a675f95199c7b96_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/10767/c15d5498411e4d939a675f95199c7b96_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/10767/c15d5498411e4d939a675f95199c7b96_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/10767/c15d5498411e4d939a675f95199c7b96_s=3000x_.jpg);
    }
  
    `}>
        </Column>


        <Column className="--left pb--40 pt--80" name={"vybaveni-nadpis"} style={{"backgroundColor":"rgba(90,73,69,1)"}}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="pt--20">
              
              <Title className="title-box fs--72 w--300 title-box--invert ls--0" style={{"maxWidth":""}} content={"Chalupa Jetřiška"}>
              </Title>

              <Text className="text-box fs--16 lh--2 mt--25" style={{"maxWidth":880}} content={"Chalupa se nachází na samém okraji NP České Švýcarsko, v okrajové části obce Jetřichovice a je ideálním výchozím bodem pro pěší turistiku v národním parku. Z jedné strany sousedí s říčkou Jetřichovická Bělá a z druhé strany chalupu obklopuje louka a les. Nachází se na polosamotě cca. 100 metrů od silnice a poskytuje krásný výhled na okolní lesy.<br><br>Objekt poskytuje ubytování až pro 7 osob, parkování je možné přímo na pozemku až pro 3 vozidla. V podkroví objektu se nachází dvě ložnice pro 4 a 2 osoby. V přízemí se nachází plně vybavená kuchyně, koupelna, WC a obývací pokoj s rozkládací pohovkou.<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"6mem7c5piqi"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--48" content={"Vnitřní vybavení"}>
              </Title>

              <Text className="text-box text-box--left" content={"<span style=\"color: rgb(5, 5, 5);\">- Krb<br>- Televize se satelitním příjmem<br>- Wifi<br><span style=\"font-weight: bold;\">Kuchyně</span><br>- Rychlovarná konvice, mikrovlnná trouba, lednička, myčka na nádobí, varná deska, jídelní stůl pro 6 osob<br><span style=\"font-weight: bold;\">Koupelna</span><br>- Pračka, vana</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box fs--48" content={"Venkovní vybavení"}>
              </Title>

              <Text className="text-box text-box--left" content={"<span style=\"color: rgb(5, 5, 5);\">- Bazén s filtrací<br>- Krytá terasa s krbem<br>- Gril<br>- Ohniště<br>- Zahradní nábytek<br>- Pískoviště, houpačky,&nbsp; skluzavka a trampolína</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box fs--48" content={"Obecně"}>
              </Title>

              <Text className="text-box text-box--left" content={"<span style=\"color: rgb(5, 5, 5);\">- Ubytování až pro 7 osob<br>- Zákaz domácích mazlíčků<br>- Nekuřácký objekt<br>- Dětská postýlka k dispozici<br>- Parkování pro 3 vozidla<br>- Vytápění krbem s rozvodem vzduchu nebo elektrickými přímotopy<br>- Teplá voda ohřívána bojlerem</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"fotogalerie1"} style={{"backgroundColor":"rgba(255,255,255,1)"}} layout={"l8"} lightbox={true}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/10767/c15d5498411e4d939a675f95199c7b96_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/10767/c15d5498411e4d939a675f95199c7b96_s=350x_.jpg 350w, https://cdn.swbpg.com/t/10767/c15d5498411e4d939a675f95199c7b96_s=660x_.jpg 660w, https://cdn.swbpg.com/t/10767/c15d5498411e4d939a675f95199c7b96_s=860x_.jpg 860w, https://cdn.swbpg.com/t/10767/c15d5498411e4d939a675f95199c7b96_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/10767/c15d5498411e4d939a675f95199c7b96_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/10767/cb92d2ee51b941db9786dea66ac6d1a7_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/10767/cb92d2ee51b941db9786dea66ac6d1a7_s=350x_.jpg 350w, https://cdn.swbpg.com/t/10767/cb92d2ee51b941db9786dea66ac6d1a7_s=660x_.jpg 660w, https://cdn.swbpg.com/t/10767/cb92d2ee51b941db9786dea66ac6d1a7_s=860x_.jpg 860w, https://cdn.swbpg.com/t/10767/cb92d2ee51b941db9786dea66ac6d1a7_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/10767/cb92d2ee51b941db9786dea66ac6d1a7_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/10767/9da31504454545d0ad1ff6ffde018749_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/10767/9da31504454545d0ad1ff6ffde018749_s=350x_.jpg 350w, https://cdn.swbpg.com/t/10767/9da31504454545d0ad1ff6ffde018749_s=660x_.jpg 660w, https://cdn.swbpg.com/t/10767/9da31504454545d0ad1ff6ffde018749_s=860x_.jpg 860w, https://cdn.swbpg.com/t/10767/9da31504454545d0ad1ff6ffde018749_s=1400x_.jpg 1400w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/10767/9e2491d0628d427cbfeadd081a9a3b72_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/10767/9e2491d0628d427cbfeadd081a9a3b72_s=350x_.jpg 350w, https://cdn.swbpg.com/t/10767/9e2491d0628d427cbfeadd081a9a3b72_s=660x_.jpg 660w, https://cdn.swbpg.com/t/10767/9e2491d0628d427cbfeadd081a9a3b72_s=860x_.jpg 860w, https://cdn.swbpg.com/t/10767/9e2491d0628d427cbfeadd081a9a3b72_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/10767/9e2491d0628d427cbfeadd081a9a3b72_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/10767/a44f1db20b1045e2a5ba77397fc69e4d_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/10767/a44f1db20b1045e2a5ba77397fc69e4d_s=350x_.jpg 350w, https://cdn.swbpg.com/t/10767/a44f1db20b1045e2a5ba77397fc69e4d_s=660x_.jpg 660w, https://cdn.swbpg.com/t/10767/a44f1db20b1045e2a5ba77397fc69e4d_s=860x_.jpg 860w, https://cdn.swbpg.com/t/10767/a44f1db20b1045e2a5ba77397fc69e4d_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/10767/a44f1db20b1045e2a5ba77397fc69e4d_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/10767/48b41d9fa8934c979aac9ccd58a3d984_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/10767/48b41d9fa8934c979aac9ccd58a3d984_s=350x_.jpg 350w, https://cdn.swbpg.com/t/10767/48b41d9fa8934c979aac9ccd58a3d984_s=660x_.jpg 660w, https://cdn.swbpg.com/t/10767/48b41d9fa8934c979aac9ccd58a3d984_s=860x_.jpg 860w, https://cdn.swbpg.com/t/10767/48b41d9fa8934c979aac9ccd58a3d984_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/10767/48b41d9fa8934c979aac9ccd58a3d984_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"v6gha873y2h"} layout={"l8"} lightbox={true}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/10767/a58b9a5ba5ab4db9a847a5af3a032174_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/10767/a58b9a5ba5ab4db9a847a5af3a032174_s=350x_.jpg 350w, https://cdn.swbpg.com/t/10767/a58b9a5ba5ab4db9a847a5af3a032174_s=660x_.jpg 660w, https://cdn.swbpg.com/t/10767/a58b9a5ba5ab4db9a847a5af3a032174_s=860x_.jpg 860w, https://cdn.swbpg.com/t/10767/a58b9a5ba5ab4db9a847a5af3a032174_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/10767/a58b9a5ba5ab4db9a847a5af3a032174_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/10767/65a3e1c92b89409d99ce08b25323984d_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/10767/65a3e1c92b89409d99ce08b25323984d_s=350x_.jpg 350w, https://cdn.swbpg.com/t/10767/65a3e1c92b89409d99ce08b25323984d_s=660x_.jpg 660w, https://cdn.swbpg.com/t/10767/65a3e1c92b89409d99ce08b25323984d_s=860x_.jpg 860w, https://cdn.swbpg.com/t/10767/65a3e1c92b89409d99ce08b25323984d_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/10767/65a3e1c92b89409d99ce08b25323984d_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/10767/603348cade0448cea7cd060dae18a2e3_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/10767/603348cade0448cea7cd060dae18a2e3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/10767/603348cade0448cea7cd060dae18a2e3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/10767/603348cade0448cea7cd060dae18a2e3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/10767/603348cade0448cea7cd060dae18a2e3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/10767/603348cade0448cea7cd060dae18a2e3_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/10767/82db55a96d5b4e13a6f3bbdde943d8cf_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/10767/82db55a96d5b4e13a6f3bbdde943d8cf_s=350x_.jpg 350w, https://cdn.swbpg.com/t/10767/82db55a96d5b4e13a6f3bbdde943d8cf_s=660x_.jpg 660w, https://cdn.swbpg.com/t/10767/82db55a96d5b4e13a6f3bbdde943d8cf_s=860x_.jpg 860w, https://cdn.swbpg.com/t/10767/82db55a96d5b4e13a6f3bbdde943d8cf_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/10767/82db55a96d5b4e13a6f3bbdde943d8cf_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/10767/64343c8d8cfa4222af0c7013d2deeb66_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/10767/64343c8d8cfa4222af0c7013d2deeb66_s=350x_.jpg 350w, https://cdn.swbpg.com/t/10767/64343c8d8cfa4222af0c7013d2deeb66_s=660x_.jpg 660w, https://cdn.swbpg.com/t/10767/64343c8d8cfa4222af0c7013d2deeb66_s=860x_.jpg 860w, https://cdn.swbpg.com/t/10767/64343c8d8cfa4222af0c7013d2deeb66_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/10767/64343c8d8cfa4222af0c7013d2deeb66_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/10767/321ef18efc36499781c4b15f1b2ae12b_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/10767/321ef18efc36499781c4b15f1b2ae12b_s=350x_.jpg 350w, https://cdn.swbpg.com/t/10767/321ef18efc36499781c4b15f1b2ae12b_s=660x_.jpg 660w, https://cdn.swbpg.com/t/10767/321ef18efc36499781c4b15f1b2ae12b_s=860x_.jpg 860w, https://cdn.swbpg.com/t/10767/321ef18efc36499781c4b15f1b2ae12b_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/10767/321ef18efc36499781c4b15f1b2ae12b_s=2000x_.jpg 2000w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--left pb--40 pt--80" name={"cenik-nadpis"} style={{"backgroundColor":"rgba(247, 245, 244, 1)"}}>
          
          <ColumnWrap className="column__flex --shape2 --center el--1 --full flex--center" style={{"backgroundColor":"rgba(90, 73, 69, 1)"}} columns={"1"} fullscreen={true}>
            
            <ColumnWrapper className="pt--20">
              
              <Title className="title-box fs--72 w--300 ls--0" style={{"maxWidth":""}} content={"<span style=\"color: rgb(255, 255, 255);\">Ceník</span>"}>
              </Title>

              <Text className="text-box text-box--center fs--16 lh--2 mt--25" style={{"maxWidth":880}} content={"Letní sezóna: Červen - Září<br>Zimní sezóna: Říjen - Květen<br>Minimální délka ubytování: 2 noci<br>Čas příjezdu od 16:00\nČas odjezdu do 10:00<br>Vratná kauce 3.000 Kč (platba při příjezdu)<br>Záloha ve výši 50% z ceny splatná do 10 dnů po rezervaci na bankovní účet pronajímatele<br><br><span style=\"font-weight: bold;\">Cena ubytování zahrnuje:</span><br>&nbsp;Dřevo do krbu a venkovního ohniště, povlečení, tablety do myčky, spotřeba vody.<br><br><span style=\"font-weight: bold;\">Cena ubytování nezahrnuje:</span><br>Elektrická energie - účtována podle skutečné spotřeby (sazba 5 kč/KWh, platba při odjezdu)<br>Poplatky obci: 15 Kč dospělá osoba/noc (platba při příjezdu). Od poplatku jsou osvobozeny osoby mladší 18-ti let a starší 70-ti let.<br><br><span style=\"font-weight: bold;\">Storno poplatky:</span><br>Klient má právo odstoupit od rezervace kdykoliv a to výhradně písemnou formou. Pokud dojde ke zrušení rezervace do 21 dnů před objednaným termínem, je rezervace zrušena bez storno poplatku. V případě zrušení rezervace 21 - 0 dnů před začátkem pobytu je klient povinen uhradit ubytovateli storno poplatky ve výši 50% z ceny pronájmu.&nbsp;Pokud dojde ke zrušení pobytu ze strany pronajímatele, zaplacená záloha bude klientovi bez odkladu vrácena v plné výši.<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--10 pt--10" name={"cenik-polozky"} style={{"backgroundColor":"rgba(247, 245, 244, 1)"}}>
          
          <ColumnWrap className="column__flex el--3" columns={"3"}>
            
            <ColumnWrapper className="pb--0 pt--0">
              
              <Subtitle className="subtitle-box subtitle-box--center w--300 mb--10" content={"<span style=\"font-weight: 700;\">Cena za týden</span><br>"}>
              </Subtitle>

              <PriceWrap className="pb--10 pl--20 pr--20 pt--10 flex--center" style={{"backgroundColor":"rgba(255,255,255,1)"}}>
                
                <ColumnWrapper >
                  
                  <Subtitle className="subtitle-box lh--1 mt--0" content={"Letní sezóna"}>
                  </Subtitle>

                </ColumnWrapper>

                <ColumnWrapper className="--right" style={{"maxWidth":150}}>
                  
                  <Subtitle className="subtitle-box fs--26 pr--0" style={{"maxWidth":150}} content={"15.000 Kč"}>
                  </Subtitle>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pb--10 pl--20 pr--20 pt--10 flex--center" style={{"backgroundColor":"rgba(255,255,255,1)"}}>
                
                <ColumnWrapper >
                  
                  <Subtitle className="subtitle-box lh--1 mt--0" content={"Zimní sezóna"}>
                  </Subtitle>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Subtitle className="subtitle-box fs--26" content={"10.000 Kč"}>
                  </Subtitle>

                </ColumnWrapper>

              </PriceWrap>

            </ColumnWrapper>

            <ColumnWrapper className="pb--0 pt--0">
              
              <Subtitle className="subtitle-box subtitle-box--center w--300 mb--10" content={"<span style=\"font-weight: 700;\">Cena za víkend (pá-ne)</span><br>"}>
              </Subtitle>

              <PriceWrap className="pb--10 pl--20 pr--20 pt--10 flex--center" style={{"backgroundColor":"rgba(255,255,255,1)"}}>
                
                <ColumnWrapper >
                  
                  <Subtitle className="subtitle-box lh--1 mt--0" content={"Zimní sezóna"}>
                  </Subtitle>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Subtitle className="subtitle-box fs--26" content={"5.000 Kč"}>
                  </Subtitle>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pb--10 pl--20 pr--20 pt--10 flex--center" style={{"backgroundColor":"rgba(255,255,255,1)"}}>
                
                <ColumnWrapper >
                  
                  <Subtitle className="subtitle-box lh--1 mt--0" content={"Letní sezóna"}>
                  </Subtitle>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Subtitle className="subtitle-box fs--26" content={"Nelze"}>
                  </Subtitle>

                </ColumnWrapper>

              </PriceWrap>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"cwoqivjncan"}>
          
          <ColumnWrap className="column__flex el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--center" content={"Rezervace"}>
              </Title>

              <Text className="text-box text-box--center" style={{"maxWidth":638}} content={"<span style=\"color: rgb(5, 5, 5);\">Nejlepší způsob, jak si chalupu zarezervovat, je přes email cihlarovi.rj@seznam.cz nebo tento kontaktní formulář. Zamluvený termín Vám obratem potvrdíme a zašleme další informace. Aktuální obsazenost chalupy naleznete po kliknutí na odkaz níže.</span><br>"}>
              </Text>

              <Button className="btn-box btn-box--center" href={"https://www.e-chalupy.cz/kalendar-obsazenosti.php?objekt=5880"} content={"Kalendář obsazenosti"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <ContactForm className="--shape2 --style1 --right" action={"/contact"} fields={[{"name":"Celé jméno","type":"text","required":true,"placeholder":"Zadejte celé jméno"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"Zadejte Váš e-mail"},{"name":"Telefonní číslo","type":"text","placeholder":"Telefon +420"},{"name":"Zpráva","type":"textarea","required":true,"placeholder":"Zadejte text zprávy"},{"name":"Odeslat","type":"submit"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Fullmap className="--style3" name={"mapa"}>
          
          <FullmapWrap className="--full">
            
            <FullmapCover zoom={"11"} place={"Jetřichovice"}>
            </FullmapCover>

          </FullmapWrap>

        </Fullmap>


        <Column className="--left pb--50 pt--50" name={"paticka"} style={{"backgroundColor":"rgba(90,73,69,1)"}}>
          
          <ColumnWrap className="column__flex --center el--4 pb--0 flex--top" columns={"4"}>
            
            <ColumnWrapper className="--left pb--20 pt--25">
              
              <Title className="title-box fs--72 w--300 title-box--invert ls--0" style={{"maxWidth":""}} content={"Kontakt:"}>
              </Title>

              <Text className="text-box fs--16 lh--2 mt--25" style={{"maxWidth":880}} content={"Chalupa Jetřiška<br>Jetřichovice 94<br>407 16 Jetřichovice u Děčína<br>Česká republika<br><br>e-mail: cihlarovi.rj@seznam.cz<br>tel.: 775 332 390, 602 435 610<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left pb--20 pt--25">
            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}